import '@olxid/olx-autos-landing-page/dist/Components/Section/Sections.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Title/Title.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BannerCarousel/BannerCarousel.scss';
import '@olxid/olx-autos-landing-page/dist/Components/CTA/CTA.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/NumberPlateInputWithLink/NumberPlateInputWithLink.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R1/BookAnAppointmentR1.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/ConditionModal.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R3/BookAnAppointment.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookAnAppointment/R3/InputRadioButton.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/BookingConfirmedWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/BookingConfirmedWidget/V2/BookingConfirmedWidget.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/PriceEstimation/PriceEstimation.scss';
import '@olxid/olx-autos-landing-page/dist/Components/StaticItem/StaticItem.scss';
import '@olxid/olx-autos-landing-page/dist/Components/TextWithIcon/TextWithIcon.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/StaticImageWithCTA/StaticImageWithCTA.scss';
import '@olxid/olx-autos-landing-page/dist/Components/SubSection/SubSection.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/StaticImageWithCTAAndDesc/StaticImageWithCTAAndDesc.scss';
/* eslint-disable react/prop-types */

import React from 'react';
import { fetchLayout, LayoutSelector, DependencyManager, locationHelpers, PanameraHomePageLayout, CampaingHelpers } from 'olx-autos-landing-page';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withRouter from 'HOCs/withRouter';
import withCookies from 'HOCs/withCookies/withCookies';
import { withConfig } from 'HOCs/withConfig/withConfig';
import LoadingPlaceholder from './LoadingPlaceholder';
import { SOURCE } from './../../constants/layout';
import { isEmpty } from 'Helpers/objects';
import withDataFetching from 'HOCs/withDataFetching/withDataFetching';
import { getCXEQueryParams } from './../../helpers/layout';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import withTrack from 'HOCs/withTrack/withTrack';
import { userIsLogged, getLoggedUser } from 'Selectors/user';
import css from './AutosHomePageWidgets.desktop.scss';
import withLocalStorage from 'HOCs/withLocalStorage/withLocalStorage';
import classNames from 'classnames';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import { fetchDisclaimer } from 'Autos/BookAppointmentV2/actions';
import { getQueryRedirectionParams } from 'Selectors/olxAutos';
import withSessionLong from 'HOCs/withSessionLong/withSessionLong';
import { INSPECTION_CREATED_DESKTOP_LAYOUT_NAME, INSPECTION_CREATED_DESKTOP_LAYOUT_NAME_CRE, VALUATION_DONE_DESKTOP_LAYOUT_NAME } from 'Constants/carousellLayoutName';
import CarouselBanner from '../CarouselBanner/CarouselBanner';

const dependencies = {
    constants: {
        platform: PLATFORM
    },
    functions: {
        getQueryRedirectionParams
    }
};

DependencyManager.init(dependencies);

function isStateAppointmentCreated(layout) {
    return layout?.name === INSPECTION_CREATED_DESKTOP_LAYOUT_NAME
    || layout?.name === INSPECTION_CREATED_DESKTOP_LAYOUT_NAME_CRE
    || layout?.name === VALUATION_DONE_DESKTOP_LAYOUT_NAME;
}

export const AutosHomePageWidgets = ({
    layout,
    isLoading,
    track,
    locationId,
    userIsLogged,
    handleIconClick,
    userId,
    userSessionLong
}) => {
    /**
     * This function is temperory, it shows self inspection button
     * on valuation done and valuation error step for the PPC user.
     * Remove this function when this is handled from the cxe.
     */
    // Commenting out the this functionality as a part of ticket PAN-71541
    /*  const showSiButton = useCallback(async () => {
        // Remove this check for PPC
        if (isPaidCampaign) {
            return;
        }

        if (olxAutosConfig && olxAutosConfig.citiesForSi) {
            if (isPaidCampaign) {
                const paidQueryParams = getSessionStorageItem('utmpk') ? JSON.parse(getSessionStorageItem('utmpk')) : undefined;

                const userCity = paidQueryParams?.campaignName?.split('|')[1];

                const isCityMatch
                    = !isEmpty(olxAutosConfig.citiesForSi.find(city => city.sphareName === userCity));

                let configToTakeForHidingSection = olxAutosConfig.inspectionCtaConfig.sectionToHideForNonMatchedCity;

                if (isCityMatch) {
                    configToTakeForHidingSection = olxAutosConfig.inspectionCtaConfig.sectionToHideForMatchedCity;
                }

                const omittedCxeSection = [
                    ...configToTakeForHidingSection
                ];

                setOmittedCxeSection(omittedCxeSection);
            }
            else {
                let siLocation = storedSiLocation;

                if (isEmpty(siLocation)) {
                    const siCity = localStorage.getItem('siCity');

                    siLocation = siCity ? JSON.parse(siCity) : undefined;
                }

                const isCityMatch
                    = eligibleRegion?.includes(parseInt(siLocation?.sphareId, 10))
                    || eligibleRegion?.includes(siLocation?.sphareId?.toString());

                let configToTakeForHidingSection = olxAutosConfig.inspectionCtaConfig.sectionToHideForNonMatchedCity;

                if (isCityMatch) {
                    configToTakeForHidingSection = olxAutosConfig.inspectionCtaConfig.sectionToHideForMatchedCity;
                }

                const omittedCxeSection = [
                    ...configToTakeForHidingSection
                ];

                setOmittedCxeSection(omittedCxeSection);
            }
        }
    }, [eligibleRegion, isPaidCampaign, olxAutosConfig, storedSiLocation, localStorage]); */
    if (isLoading) {
        return (
            <div>
                <LoadingPlaceholder />
            </div>
        );
    }

    if (isEmpty(layout)) {
        return null;
    }

    const dynamicParamsValues = {
        userId,
        userSessionLong
    };

    return (
        <div className={ classNames(
            { [css.autosHomePageWidgets]: (IS_DESKTOP) }
        ) }
        >

            {IS_DESKTOP && !isStateAppointmentCreated(layout)
                ? <CarouselBanner layout={ layout } />
                : <PanameraHomePageLayout
                    layout={ layout }
                    track={ track }
                    locationId={ locationId }
                    userIsLogged={ userIsLogged }
                    handleIconClick={ handleIconClick }
                    dynamicParamsValues={ dynamicParamsValues }
                />
            }

        </div>
    );
};

AutosHomePageWidgets.propTypes = {
    layout: PropTypes.object,
    isLoading: PropTypes.bool,
    userId: PropTypes.string,
    userSessionLong: PropTypes.string
};

const actionCreator = (dispatch, reqProps) => {
    const params = getCXEQueryParams(reqProps, null, SOURCE.OLX);

    dispatch(fetchDisclaimer());

    return dispatch(fetchLayout({ ...params, layoutSource: SOURCE.OLX }));
};

export const mapStateToProps = (state, ownProps) => {
    const queryString = ownProps && ownProps.location && ownProps.location.query;
    const isPaidCampaign = CampaingHelpers.isPaidCampaign(queryString, ownProps?.marketConfig?.get());

    const locationId = locationHelpers.getLocationId(state.locations.selectedLocation);
    const langs = ownProps?.marketConfig?.get('langs');
    const lang = langs && langs[0] && langs[0].smartlingLocaleCode;
    const selectorProps = {
        ...ownProps,
        locationId,
        layoutSource: SOURCE.OLX,
        lang
    };

    const {
        getLayoutSelector,
        isFetching
    } = LayoutSelector;

    return {
        layout: getLayoutSelector(state, selectorProps),
        isLoading: isFetching(state, selectorProps),
        locationId,
        lang,
        userIsLogged: userIsLogged(state),
        isPaidCampaign,
        storedSiLocation: state.locations.siLocation,
        eligibleRegion: state.bookAppointment?.disclaimer?.data?.eligibleRegion?.filter?.locationsIds,
        userId: getLoggedUser(state).id
    };
};

export default compose(
    withCookies,
    withRouter,
    withTrack,
    withLocalStorage,
    withSessionLong,
    withConfig('marketConfig'),
    connect(mapStateToProps),
    withDataFetching(actionCreator)
)(React.memo(AutosHomePageWidgets));

